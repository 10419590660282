import {cloneObj} from '@/utils/xyhUtils'
import VueDatepickerLocal from 'vue-datepicker-local'

function chkTW(content) {
    let modeAB = /^([0-9]*[AB][\-]?[0-9]*[ymdw]?)$/   //绝对定位模式
    let modeExt = /^(\+?\-?[1-9]\d*[ymdw]?)$/   //时间延展模式
    let modeExt2 = /^(\+[1-9][0-9]*\-[1-9][0-9]*[ymdw]?)$/   //时间延展模式2
    let modeLimit = /^([<>]?=?[1-9]\d*[ymdwT]?)$/     //周期限制模式
    let modeLimit2 = /^T$/     //受父窗口限制的周期模式

    var strs = content.trim().split(/\s+/)

    if(strs.length < 1 || strs.length > 3) return -4;
    if( !modeAB.test(strs[0]) )  return -1;
    let ref = Number(strs[0].substr(0, strs[0].search(/[AB]/)))
    if(strs.length == 1) return ref;
    if( !modeAB.test(strs[1]) && !modeExt.test(strs[1]) && !modeExt2.test(strs[1]) )  return -2
    if(strs.length == 2) return ref;
    if(strs[2] != 'T' && !modeLimit.test(strs[2]) ) return -3
    return ref
}

function recoNoteFlag(s) {
    var NoteFlagModes = [ /(\[[0-9]+\])$/, /(\[[a-zA-Z]\])$/, /(\s\d+)$/, /(\s[a-zA-Z])$/, /(\*+)$/ ]
    var res = [s, '']
    NoteFlagModes.some( p => {
    	let m = p.exec(s)
		if(m) res = [s.substr(0, m.index).trim(), m[0].trim()]
		return m && res
    })
    return res
}

function chkTimeSpans(spans) {
    var mps =[]
    for (let i of spans) {
        if(i.parent < 0) return false
        mps[i.k] = i.parent
    }

    for ( let i of spans ) {
        let n = 0, p = i.k
        do {
            if(n++ > spans.length) return false
            if(typeof(mps[p]) == 'undefined') return false
            p = mps[p]
        } while (p != 0)
    }
    return true
}

function chkItems(items) {
    var mps ={}
    for (let i of items) {
        let n = i.name.trim()
        if(n.length < 1) return false
        if(typeof(mps[n]) != 'undefined') return false
        mps[n] = 1
    }
    items.forEach(i => i.name = i.name.trim())
    return true
}


export default {
    name:"editProfile",
    props: ['id'],
	data() {
	    var types= [
		    {id:1, name:'知情同意', kWords:'知情'},
			{id:2, name:'随机化分组', kWords:'随机'},
			{id:3, name:'入排标准评估', kWords:'入排, 入选, 排除, 入组'},
			{id:4, name:'病史采集', kWords:'史, 人口统计, 既往'},
			{id:5, name:'体格检查', kWords:'体征,体格,体重,身高,生命征,血压,心率,心律,体温,呼吸'},
			{id:6, name:'影像学检查和评估', kWords:'CT,MRI,X光,B超,超声,影像,骨扫描,核磁,断层,PET'},
			{id:7, name:'量表、问卷或综合评估', kWords:'ECOG,量表,生活质量,RECIST,Questionnaire,问卷'},
			{id:8, name:'物理学检查（心电图/脑电图等）', kWords:'心电, 脑电'},
			{id:9, name:'血尿便检查', kWords:'常规,凝血,血生化,妊娠,肝功能'},
			{id:10, name:'本院生物样本采集和检查', kWords:'血浆,血清,穿刺,骨穿'},
			{id:11, name:'中心实验室生物样本管理', kWords:'PK,PD '},
			{id:12, name:'研究药品管理', kWords:'药品, 点药,用药,给药'},
			{id:13, name:'病历&EDC录入&疑问表', kWords:'日记 ,病历,EDC,疑问表,Query,Queries'},
			{id:14, name:'研究文件管理', kWords:'研究文件'},
			{id:15, name:'AE/SAE管理', kWords:'SAE, AE,不良'},
			{id:16, name:'EC递交管理', kWords:'伦理,EC'},
			{id:17, name:'患者随访', kWords:'随访'},
			{id:18, name:'研究治疗和伴随治疗', kWords:'治疗'},
			{id:-1, name:'其他', kWords:''}
		]

		var typeMaps =[]
		types.forEach(t => {
		  typeMaps.push( {kw: t.name.toLowerCase().trim(), type:t.id} )
		  t.kWords.split(',').map( v => v.trim() ).filter(v => v.length > 0 ).forEach(v => {typeMaps.push( {kw:v.toLowerCase(), type:t.id} )})
		})

		return {
            type: 0,
		    projects:[],
		    profiles: [],
		    profileForCopy:0,
		    showDoc:false,
		    colEditing: -1,

		    itemsCopied: '',
		    tmpItems: [],
		    defaultItems: [],
			itemKMax: 0,

			tmpTimeWins: [
				{k: 1, name:'筛选期', content:'A-28 A-1', noteFlag:'', parent: 0},
				{k: 2, name:'治疗期', content:'A 4w 3T', noteFlag:'', parent: 0},
				{k: 3, name:'cxd1', content:'2A1 +-3', noteFlag:'', parent: 0},
				{k: 4, name:'cxd15', content:'2A15 +-3', noteFlag:'', parent: 0},
				{k: 5, name:'随访期', content:'B 3m 3T', noteFlag:'', parent: 0}
			],
			spanKMax:5,

			taskMap:{},
			noteMap:{},

		    profile:{
		        id:0,
				num:'',//方案编号
		        name: '',
				sname:'',//方案简称
				stage: 0,
				version:'',
				sponsor: 0,
				sponsorName: '',
				smo: 0,
				smoName: '',
				cro: 0,
				croName: '',
				group: '',
				project: 0,

				items: [],
				timeWins: [],
				plan:[],
				notes:{},
			},

			itemTypes: types,
			typeMaps : typeMaps,
			taskDirOpts : [{id : 0, name: '无需'}, {id : 1, name: '需做'}, {id: 2, name: '按需一次'}, {id: 3, name: '按需多次'}],
			dirDis : ['','√', '~1', '~M']
		}
	},

	created() {
	    this.token = this.getCookie('token')
	    if(this.id > 0) this.getProfile(this.id)
	    this.getProfiles()
	    this.getProjects()
	},

	filters:{
	    profileTitle:function(profile) {
	        return '<'+profile.num + '> ' + profile.sname
	    },

	    projectTitle:function(project) {
          return '<'+project.projectNumber + '> ' + project.shortName
      }
	},

	methods: {
	    getProfiles() {
        	var _this = this;
        	this.$http.post('/API/PLAT/profiles', {} , {
        		headers: { "token": _this.token }
        	}).then(function(response) {
        		if(response.data.code == 200) {
        			_this.profiles = response.data.data
        		}
        	})
        },

      getProjects() {
        	var _this = this;
        	this.$http.post('/API/PLAT/projects', {} , {
        		headers: { "token": _this.token }
        	}).then(function(response) {
        		if(response.data.code == 200) {
        			_this.projects = response.data.data
        		}
        	})
        },

      canEditTw(twk) {
          return this.colEditing < 0 || this.colEditing >= this.profile.timeWins.length || this.profile.timeWins[this.colEditing].k == twk
      },

      toggleDir(itk, twk) {
          if(this.canEditTw(twk))  this.taskMap[[itk, twk]].dir = this.taskMap[[itk, twk]].dir == 1? 0:1
      },

      toggleDir2(itk, twk) {
          if(this.canEditTw(twk)) this.taskMap[[itk, twk]].dir = this.taskMap[[itk, twk]].dir == 2? 3:2
      },

        toggleRow(itk) {
            if(this.colEditing >= 0 && this.colEditing < this.profile.timeWins.length) return;
            let v = this.profile.timeWins.some(tw=>this.taskMap[[itk, tw.k]].dir != 1) ? 1 : 0
            this.profile.timeWins.forEach(tw =>this.taskMap[[itk, tw.k]].dir = v)
        },

         toggleCol(twk) {
              if(!this.canEditTw(twk))  return;
             let v = this.profile.items.some(it=>this.taskMap[[it.k, twk]].dir != 1) ? 1 : 0
             this.profile.items.forEach(it =>this.taskMap[[it.k, twk]].dir = v)
         },

          toggleColEditing(idx) {
              this.colEditing = this.colEditing== idx? -1 : idx
          },

	    upProfile(profile, copy) {
          this.colEditing = -1
	        if(copy) profile.id = this.profile.id
	        this.profile = profile
	        console.log(this.profile.id)

	        this.tmpItems = cloneObj(this.profile.items);
	        this.tmpTimeWins = cloneObj(this.profile.timeWins)
            this.itemKMax = this.tmpItems.reduce(  (m, it) => m >= it.k ? m : it.k , this.itemKMax )
            this.spanKMax = this.tmpTimeWins.reduce( (m, it) => m >= it.k ? m : it.k , this.spanKMax )

            var flags = this.profile.items.map(i => i.noteFlag)
            flags = this.profile.timeWins.map(t => t.noteFlag).concat(flags)
            flags = this.profile.plan.map(it => it.noteFlag).concat(flags);
            flags = flags.map(it=>it.trim()).filter(it => it.length > 0)

            var nmpas = {}
            this.profile.notes.forEach(it => nmpas[it.flag.trim()] = it)
            flags.forEach(i => { if(typeof(nmpas[i]) == 'undefined')  nmpas[i] = {flag:i, note:''} })
            this.noteMap = nmpas;

            var tmap = {}
            this.profile.plan.forEach( task => tmap[[task.itemK, task.twK]] = task )
            this.profile.items.forEach(it => {
                this.profile.timeWins.forEach(tw => {
                    if(typeof(tmap[[it.k, tw.k]]) == 'undefined')
                        tmap[[it.k, tw.k]] = { itemK: it.k, twK: tw.k, dir:0, noteFlag: '' }
                })
            })
            this.taskMap = tmap

	    },

		getProfile(id, copy) {
			var _this = this;
			this.$http.post('/API/PLAT/profile', {id:id} , {
				headers: { "token": _this.token }
			}).then(function(response) {
				if(response.data.code == 200)
				    _this.upProfile ( response.data.data, copy)
			})
		},

	    applyItemsAndTws(){
	        this.colEditing = -1
	        if(!(this.tmpTimeWins.length >= 1 || this.tmpItems.length >= 1))  { alert("请添加任务条目或时窗！"); return }
	        if(!chkItems(this.tmpItems))  { alert("请检查任务条目！"); return }
	        this.tmpTimeWins.forEach(it => { it.parent =  chkTW(it.content) } )
            for( let v of this.tmpTimeWins) { if (v.parent < 0) { alert("时窗定义语法有错！"); return } }
	        if(!chkTimeSpans(this.tmpTimeWins))  { alert("有错，请检查！"); return }

	        this.profile.items = cloneObj(this.tmpItems)
	        this.profile.timeWins = cloneObj(this.tmpTimeWins)

	        var n = {},  o = this.taskMap
	        this.profile.items.forEach( i => {
	          this.profile.timeWins.forEach( t => {
	            n[[i.k, t.k]] = typeof(o[[i.k, t.k]]) != 'undefined' ? o[[i.k, t.k]] : {itemK:i.k, twK:t.k, dir: 0, noteFlag:''}
	          })
	        })
	        this.taskMap = n
	    },

	    applyNoteFlags(){
	        var nmap = {}
	        var flags = this.profile.items.map(i => i.noteFlag)
            flags = this.profile.timeWins.map(t => t.noteFlag).concat(flags)
            flags = this.profile.plan.map(it => it.noteFlag).concat(flags);
            flags = flags.map(it=>it.trim()).filter(it => it.length > 0)
          	flags.forEach( f => nmap[f] = (typeof(this.noteMap[f]) != 'undefined')? this.noteMap[f] :  {flag:f, note:''} )
          	this.noteMap = nmap
          	console.log(this.noteMap);
	    },

		save() {
            this.profile.plan = Object.values(this.taskMap).filter(v =>v.dir > 0)
            this.profile.notes = Object.values(this.noteMap)

            var _this = this;
            this.$http.post('/API/PLAT/addProfile', this.profile , {
            	headers: { "token": _this.token }
            }).then(function(response) {
            	if(response.data.code == 200) {
            		console.log(response)
            		 alert("添加成功")
            		 _this.gotoProfile(response.data.data)
            	} else { alert("添加方案失败！")}

            })
		},

		checTwFlag(){
		    this.tmpTimeWins.forEach(it => {
		        if(!(it.noteFlag.trim())) {
		            var p = recoNoteFlag(it.name);
		            it.name = p[0]
		            it.noteFlag = p[1]
		        }
		    })
		},

		genItems(){
            var strs = this.itemsCopied.split('\n').map( v => v.trim() ).filter(v => v.length > 0 )
            var items = []
            strs.forEach( s => {
                let p = recoNoteFlag(s)
                this.addItem(items, p[0], 2, p[1])
            })

            this.defaultItems.filter(
                v => !(items.some( it => it.name.toLowerCase() == v.toLowerCase() ))
            ).forEach(
                n => this.addItem(items, n, 3, '')
            )
            this.tmpItems = items
	    },

	    addItem(items, itName, genWay, flag){
            var type = 0;
            var sn = itName.toLowerCase()
            this.typeMaps.some(km => {
              if(sn.indexOf(km.kw) >= 0) {
                type = km.type
                return true
              }
              return false
            })
            items.push({k: ++this.itemKMax, name: itName, type: type, genWay: genWay, noteFlag: flag})
		},

        addBlankItem(idx){
            var index = idx < 0 ? this.tmpItems.length : idx
            this.tmpItems.splice(index, 0, {k: ++this.itemKMax, name: '', type: 0, genWay: 1, noteFlag: ''})
            console.log(this.tmpItems)
        },

		addBlankTSpan(){
            this.tmpTimeWins.push({k: ++this.spanKMax, name:'', content:'', noteFlag:''})
		},

		gotoProfile(id) {
			this.$router.push({
				name: 'profile',
				params: {
					id: id
				}
			})
		},
	},
}
